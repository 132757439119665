<template>
<div v-if="loginShow">
    <MasterHeader />
    <v-container>
        <h1 class="text-center mb-10 page_title">施設案内写真 設定</h1>
        <p class="text-center">「写真〇を変更する」で画像ファイルを選択すると、写真が変更されます
        </p>
        <v-row class="mt-8">
            <v-col cols="12" xl="4" lg="4" md="4" sm="6" v-for="i in 5" :key="i">
                <div class="photo_box ">
                    <img :src="photos[i-1]" class="photo my-6">
                    <div @click="no=i-1" class="input_photo relative">
                        <v-file-input show-size :label="'写真'+i+'を変更する'" outlined @change="uploadFile" accept="image/*" v-if="show" />
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import axios from "axios"
import Login from '@/mixins/login'
import d from "@/d"

import MasterHeader from '@/components/MasterHeader.vue'
export default {
    mixins: [Login],
    data() {
        return {
            files: [],
            photos: [],
            no: 0,
          url: '../storage/imgs/',
            show: true,
            bit: 3145728,
        }
    },
    components: {
        MasterHeader
    },
    async mounted() {
        const response = await axios.post('/master/photo_load')
        this.photos = response.data.photos.map(item => this.url + item.name)

    },
    methods: {
        async uploadFile(val) {
            await d.sleep(50)
            if (val.size > this.bit) {
                this.show = false
                alert('画像サイズは１枚あたり３MBまでです')
                await d.sleep(10)
                this.show = true
                return false
            }

            this.photos.splice(this.no, 1, URL.createObjectURL(val))

            const formData = new FormData()

            formData.append('file', val)
            formData.append('id', this.no + 1)
            await axios.post('/master/photo_upload', formData)
            this.show = false
            await d.sleep(10)
            this.show = true
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/master/style.scss";
@import "@/assets/sass/facility.scss";
@import "@/assets/sass/master/facility.scss";
</style>
